import { Injectable } from '@angular/core';

import { UserProfile } from '../../model';
import { BaseFeatureModuleService } from '../base-feature-module-service';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class UserProfileService extends BaseFeatureModuleService<UserProfile> {
    // Observable string source.
    private  profileBS$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);

    // Observable string stream.
    profile$: Observable<UserProfile> = this.profileBS$.asObservable();
    constructor(
        private apiSvcC: ApiService
    ) {
        super(apiSvcC, 'user/profile', {});
    }

    getProfile() {
        this.getItem('').subscribe(data => {
            this.profileBS$.next(data);
        }) ;
    }

    getProfileValue(): UserProfile {
        return this.profileBS$.getValue();
    }
}
