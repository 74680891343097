import { EquipmentServiceInterface } from './equpment-service.interface';

export class EquipmentServiceEditOut implements EquipmentServiceInterface {
    public type: 'equipment-edit-interface';
    public Id: number;
    public AssignedId: string;
    public DueDate: string;
    constructor(
        params?: {
            Id?: number,
            Key?: string,
            Name?: string,
        }
    ) {
        this.Id = params['Id'] || 0;
        this.AssignedId = params['AssignedId'] || '';
        this.DueDate = params['DueDate'] || '';
    }
}
