import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BeltServiceListComponent } from './belt/belt-service-list/belt-service-list.component';
import {SharedDatatableModule } from './shared-datatable.module';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { BeltServiceListDependencyResolverService } from './belt/belt-service-list/belt-service-list-dependency.resolver';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { CustomDateFieldModule } from './custom-date-field/custom-date-field.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedDatatableModule,
    PipesModule,
    OwlDateTimeModule,
    LocalizeRouterModule,
    CustomDateFieldModule
  ],
  declarations: [
    BeltServiceListComponent
  ],
  exports: [
    BeltServiceListComponent
  ],
  providers: [
    BeltServiceListDependencyResolverService
  ]
})
export class SharedBeltServiceListModule { }
