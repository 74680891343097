import { FieldBase } from './field-base';

export class DateTimeField extends FieldBase<string> {
    controlType = 'datetimebox';
    pickerType: string;
    min: any;
    max: any;
    placeholder: string;
    stepMinute: number;
    rangeMode: string;

    constructor(options: {} = {}) {
        super(options);
        this.pickerType = options['pickerType'] || 'both';
        this.min = options['min'] || null;
        this.max = options['max'] || null;
        this.stepMinute = options['stepMinute'] || 1;
        this.rangeMode = options['rangeMode'] || 'single';
    }
}
