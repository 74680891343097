export class Person {
    public Id: number;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Phone: string;
    public Position: string;
    public uid: string;
    constructor(
        params: {
            Id?: number,
            FirstName?: string,
            LastName?: string,
            Email?: string,
            Phone?: string,
            Position?: string
            uid?: string
        }
    ) {
        this.Id = params.Id || 0;
        this.FirstName = params.FirstName || '';
        this.LastName = params.LastName || '';
        this.Email = params.Email || '';
        this.Phone = params.Phone || '';
        this.Position = params.Position || '';
        this.uid = params.uid || '';

    }
}
