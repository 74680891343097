import { Injectable } from '@angular/core';

@Injectable()
export class DatatablePageService {
  private _page: number;
  private _sort: any;
  private _tableId: string;

  constructor() {}

  get page(): number {
    return this._page;
  }

  set page(page: number) {
    this._page = page;
  }

  get sort(): any {
    return this._sort;
  }

  set sort(sort: any) {
    this._sort = sort;
  }

  get tableId(): string {
    return this._tableId;
  }

  set tableId(tableId: string) {
    this._tableId = tableId;
  }

  getQueryParam() {
    const tmpObject = {};

    if (this.page > 1) {
      tmpObject['page'] = this.page;
    }

    if (this.sort && this.sort[0]) {
      tmpObject['dir'] = this.sort[0].dir;
      tmpObject['prop'] = this.sort[0].prop;
    }
    return tmpObject;
  }
}
