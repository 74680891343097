import { FieldBase } from './field-base';

export class TextareaField extends FieldBase<string> {
    controlType = 'textarea';
    placeholder: string;
    rows: number;

    constructor(options: {} = {}) {
        super(options);
        this.rows = options['rows'] || 3;
        // this.placeholder = options['placeholder'] || this.label && ('Enter ' + this.label.toLowerCase()) || '';
    }
}
