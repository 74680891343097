import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class StatisticService {

    constructor(private apiSvc: ApiService) { }

    getAll(args: { params?: StringStringMap}) {
        return this.apiSvc
                .get('stat', args.params || {})
                .pipe(map((data: any) =>  data));
    }

    getBeltDeprecation(args: { params?: StringStringMap}): Observable<any[]> {
        return this.apiSvc
                .get('stat/belt/deprecation', args.params || {})
                .pipe(map((data: any) =>  data));
    }

    getRouteDeprecation(args: { params?: StringStringMap}): Observable<any[]> {
        return this.apiSvc
        .get('stat/route/deprecation', args.params || {})
        .pipe(map((data: any) =>  data));
    }

    getBeltService(args: { params?: StringStringMap}): Observable<any[]> {
        return this.apiSvc
        .get('stat/belt-service', args.params || {})
        .pipe(map((data: any) =>  data));
    }

    getIncidentReport(args: { params?: StringStringMap}): Observable<any[]> {
        return this.apiSvc
        .get('stat/incident', args.params || {})
        .pipe(map((data: any) =>  data));
    }

    getComplaintReport(args: { params?: StringStringMap}): Observable<any[]> {
        return this.apiSvc
        .get('stat/complaint', args.params || {})
        .pipe(map((data: any) =>  data));
    }
}
