import { Component, OnInit, Input } from '@angular/core';
import { Image, PlainGalleryConfig, PlainGalleryStrategy, LineLayout, AdvancedLayout } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input()
  images: Image[] = [];

  // Gallery variables
  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  slideConfig = {
    infinite: false,
    sidePreviews: { show: false, size: { width: '100px', height: 'auto' } }
  };
  plainGalleryConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: 'auto', height: '80px' }, { length: 2, wrap: true }, 'flex-start')
  };

  constructor() { }

  ngOnInit() {
  }

  openImageModalRowDescription(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    // tslint:disable-next-line:max-line-length
    this.customPlainGalleryRowDescConfig = Object.assign({}, this.customPlainGalleryRowDescConfig, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

}
