import { FormLocalDataService } from './../../core/service/form-local-data.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService, AuthService, CFG, DynamicFormComponent, FieldBase, TextboxField, SettingsService, WINDOW } from '@app/core';
import { ComponentTitle } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends ComponentTitle implements OnInit {
    @ViewChild('df') private df: DynamicFormComponent;

    fields: FieldBase<any>[] = [];
    mainTranslateKey: string;

    private ls: any;
    private loginForm: FormGroup;

    constructor(private router: Router,
                private authSvc: AuthService,
                protected appSvc: AppService,
                public settingsSvc: SettingsService,
                private translate: TranslateService,
                private lfdSvc: FormLocalDataService,
                private toastrSvc: ToastrService,
                private spinnerSvc: NgxSpinnerService,
                @Inject(WINDOW) private window: Window) {
                    super(appSvc);
                    this.ls = this.window.localStorage;
                }

    ngOnInit(): void {
        super.ngOnInit();

        this.mainTranslateKey = 'signin';

        this.buildFields();
    }

    buildFields(): void {
        this.fields = [
            new TextboxField({
                type: 'email',
                key: 'Username',
                label: this.translate.instant(`${this.mainTranslateKey}.email`),
                required: true,
                maxLength: 100,
                translateKey: 'signin.email'
            }),

            new TextboxField({
                type: 'password',
                key: 'Password',
                label: this.translate.instant(`${this.mainTranslateKey}.password`),
                required: true,
                translateKey: 'signin.password'
            })
        ];
    }

    onSubmit(): void {
        // Get the form group from the dynamic form component
        const loginForm = this.df.form;

        // Disable the form
        loginForm.disable();

        // Call login request
        this.authSvc
            .login(loginForm.value)
            .subscribe(
                (data) => {
                    this.settingsSvc.setSettings(data.data.Settings);
                    this.ls.selectedFilters = JSON.stringify({});
                    this.toastrSvc.clear();
                    this.goContinue();
                },
                () => {
                    // Enable the form
                    loginForm.enable();

                    // Invalidate the field
                    loginForm.controls.Password.setErrors({ 'incorrect': true });
                    // Set error message
                    this.fields[1].error = CFG.error.E007;
                });
    }

    private goContinue() {
        if ( this.lfdSvc.redirectPage() ) {
            return;
        }

        // Get redirect url
        const redirectUrl: any = this.authSvc.redirectUrl;

        // Clear redirect url
        this.authSvc.redirectUrl = '';

        // Show ng spinner before loading the next page
        this.spinnerSvc.show();

        // Redirect to the url
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        }
        // Redirect to the main route by the user role.
        else {
            this.appSvc.navigateToMainRoute();
        }
    }
}
