import { CyNode, CyEdge, CyElement } from '../model';

export function CyItemFactory(typeOfItem: string): any {
    let item;
    switch (typeOfItem) {
        case 'edge': {
           item = new CyEdge();
           break;
        }
        case 'element': {
           item = new CyElement();
           break;
        }
     }
     return item;
}
