export class BeltPart {
    Id: number;
    Name: string;
    PartCategoryId: number;
    UniqueId: string;
    DateBought: string;
    DateAdded: string;
    DeprecationDate: string;
    Deleted: boolean;
    Changed: boolean;
    PartTypeId: number;
    constructor(
        params: {
            Id?: number,
            Name?: string,
            PartCategoryId?: number,
            UniqueId?: string,
            DateBought?: string,
            DateAdded?: string,
            DeprecationDate?: string,
            PartTypeId?: number;
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.PartCategoryId = params['PartCategoryId'] || 0;
        this.PartTypeId = params['PartTypeId'] || 0;
        this.UniqueId = params['UniqueId'] || '';
        this.DateBought = params['DateBought'] || '';
        this.DateAdded = params['DateAdded'] || '';
        this.DeprecationDate = params['DeprecationDate'] || '';
    }
}
