import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { ServiceRoute } from '../model';

export const ServiceRouteService = new InjectionToken<BaseFeatureModuleService<ServiceRoute>>('ServiceRoute');

export function serviceRouteSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<ServiceRoute> {
    return new BaseFeatureModuleService<ServiceRoute>(apiSvc, 'route/service',
        {
            getItems: {
                expand: 'Photos,Inspection.User,Inspection.InspectionType,Inspection.Route.Park,AssignedTo',
                fields: '*,Inspection.Route.Id,Inspection.Route.Name, Inspection.Route.ParkId, Inspection.Route.Color'
            },
            getItem: {
                // tslint:disable-next-line:max-line-length
                expand: 'Photos,RepairPhotos,Inspection.Route.Park,ComponentPart,ComponentName,RouteComponentPartCategory,Inspection.User',
            }
        }
    );
}
