import { FieldBase } from './field-base';

export class ColorBoxField extends FieldBase<string> {
    controlType = 'colorbox';

    constructor(options: {} = {}) {
        super(options);
        this.value = options['value'] || '#ffffff';
    }
}
