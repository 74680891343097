import {
    animation,
    style,
    animate,
    keyframes
  } from '@angular/animations';

  export const zoomIn = animation(
    [
      animate(
        '{{ timing }}s {{ delay }}s',
        keyframes([
          style({
            opacity: 0,
            transform: 'scale3d(.9, .9, .9)',
            offset: 0
          }),
          style({
            opacity: 1,
            transform: 'scale3d(1, 1, 1)',
            offset: 0.5
          })
        ])
      )
    ],
    {
      params: { timing: 1, delay: 0 }
    }
  );
