import { Observable ,  BehaviorSubject } from 'rxjs';

export class SideNavService {
    lastState = false;

    // Observable string source.
    private stateBS$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.lastState);

    // Observable string stream.
    state$: Observable<boolean> = this.stateBS$.asObservable();

    open() {
        this.stateBS$.next(true);
    }

    close() {
        this.stateBS$.next(false);
    }

    // toggle() {
    //     this.stateBS$.next(!this.lastState);
    // }
}
