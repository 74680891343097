import { MOMENT_FORMATS } from '@app/core/config/time-zone.format';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { TranslateService } from '@ngx-translate/core';
import { PreviousRouteService } from './core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    year = new Date().getFullYear();
    constructor(
            private dateTimeAdapter: DateTimeAdapter<any>,
            private previousUrlSvc: PreviousRouteService,
            private translate: TranslateService) {
        moment.updateLocale('nl', MOMENT_FORMATS['nl']);
        moment.updateLocale('en-GB', MOMENT_FORMATS['en-GB']);
        moment.updateLocale('de', MOMENT_FORMATS['de']);
        dateTimeAdapter.setLocale(this.translate.currentLang);
    }
}
