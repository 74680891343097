import { FieldBase } from './field-base';

export class TextboxField extends FieldBase<string> {
    controlType = 'textbox';
    type: string;
    placeholder: string;
    min: any;
    max: any;
    appendBox: boolean;
    appendIcon: string;
    tooltipText: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
        // this.placeholder = options['placeholder'] || this.label && ('Enter ' + this.label.toLowerCase()) || '';
        this.min = options['min'] || null;
        this.max = options['max'] || null;
        this.appendBox = options['appendBox'] || false;
        this.appendIcon = options['appendIcon'] || '';
        this.tooltipText = options['tooltipText'] || false;
    }
}
