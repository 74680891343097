import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Route, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { BehaviorSubject ,  Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { CFG } from '../config';
import { TokenService } from '../auth/token.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable()
export class AppService {

    // Observable string source.
    private routeDataBS$: BehaviorSubject<Data> = new BehaviorSubject<Data>({});

    // Observable string stream.
    routeData$: Observable<Data> = this.routeDataBS$.asObservable();

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private localize: LocalizeRouterService,
                private titleSvc: Title,
                private tokenSvc: TokenService) {

        // Updates the page title based on route changes.
        this.router.events
            .pipe(
                // Filter the events by the `NavigationEnd` type.
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                // Swap what we’re observing, return the `activatedRoute` into the stream.
                map(() => this.activatedRoute),
                // Find and return the deeper children property.
                map((route: ActivatedRoute) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                // Use routes only for the `primary outlet`.
                filter((route: ActivatedRoute) => route.outlet === 'primary'),
                mergeMap((route: ActivatedRoute) => route.data)
            )
            .subscribe((data: Data) => {
                // Set the real page title via the angular `Title` service.
                this.titleSvc.setTitle(CFG.defaultTitle + (data.title && (' - ' + data.title) || ''));

                // Add the route data to the stream.
                this.routeDataBS$.next(data);
            });
    }

    /**
     * Find the main route by the user role and redirect to that route.
     */
    navigateToMainRoute(): void {
        // const mainRouteByRole: Route = this.router.config[0]['children'].find(
        //     route => route.data && route.data.rid === this.tokenSvc.payload.rid
        // );
        // if (mainRouteByRole) {
        //     const url = this.localize.translateRoute(`/admin/dashboard`);
        //     this.router.navigate([url]);
        // }
        const url = this.localize.translateRoute(`/app/dashboard`);
            this.router.navigate([url]);
    }

    // navigateByParent(childRoute?: string | string[]): void {
    //     let commands = ['../'];
    //
    //     if (childRoute) {
    //         if (!Array.isArray(childRoute)) {
    //             childRoute = [childRoute];
    //         }
    //
    //         Object.assign(commands, childRoute);
    //     }
    //     this.router.navigate(commands);
    // }
}
