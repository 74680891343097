import { Directive, HostListener } from '@angular/core';

import { SideNavService } from './sidenav.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[closeSideNav]'
})
export class CloseDirective {
    @HostListener('click', ['$event.target'])
    private onClick() {
        this.sideNavService.close();
    }

    constructor(private sideNavService: SideNavService) { }
}
