import { EquipmentServiceInterface } from './equpment-service.interface';

export class EquipmentServiceRepairOut implements EquipmentServiceInterface {
    public type: 'equipment-service-repair';
    public Id: number;
    public Barcode: string;
    public Description: string;
    public StatusId: number;
    public DateBought: string;
    public Service: {RepairDescription: string};
    public Parts: any[];
    public Inspections: any[];

    constructor(params) {
        this.Id = params['Id'] || 0;
        this.Barcode = params['Barcode'] || '';
        this.Description = params['Description'] || '';
        this.StatusId = params['StatusId'] || 0;
        this.DateBought = params['DateBought'] || '';
        this.Parts = params['DueDate'] || [];
        this.Service = params['Service'] || {};
        this.Inspections = params['Inspections'] || {};
    }
}
