export class RoutePartCategory {
    Id: number;
    Name: string;
    SupplierId: number;
    ComponentTypeId: number;
    Description: string;
    Deprecation: number;
    DeprecationPeriod: number;
    FileUrl: string;
    Manuals: File[];

    // Expands
    Supplier: string;
    ComponentType: string;

    constructor(
        params: {
            Id?: number,
            SupplierId?: number,
            Name?: string,
            ComponentTypeId?: number,
            Description?: string,
            Deprecation?: number,
            DeprecationPeriod?: number,
            FileUrl?: string,
            Manuals?: File[],
            Supplier?: string,
            ComponentType?: string,
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.SupplierId = params['SupplierId'] || 0;
        this.ComponentTypeId = params['ComponentTypeId'] || 0;
        this.Description = params['Description'] || '';
        this.Deprecation = params['Deprecation'] || 0;
        this.DeprecationPeriod = params['DeprecationPeriod'] || 0;
        this.FileUrl = params['FileUrl'] || '';
        this.Manuals = params['Files'] || [];

        // Expands
        this.Supplier = params['Supplier'] || '';
        this.ComponentType = params['ComponentType'] || '';
    }
}
