import { FieldBase } from './field-base';
import { environment } from '@env/environment';

export class RecaptchaField extends FieldBase<string> {
    controlType = 'recaptcha';
    options: SelectOptionsObj[];
    sitekey: string;

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
        this.sitekey = environment.sitekey;
    }
}
