import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignInComponent } from './sign-in/sign-in.component';
import { AuthGuardService } from '@app/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordComponent } from '@app/auth/forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from '@app/auth/forgot-password-reset/forgot-password-reset.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TokenFailComponent } from './token-fail/token-fail.component';

const authRoutes: Routes = [
    {
        path: 'sign-in',
        component: SignInComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Sign In',
            titleTranslateKey: 'signin.title',
            withAuth: false
        }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Forgot Password',
            titleTranslateKey: 'forgotPassword.title',
            withAuth: false
        }
    },
    {
        path: 'not-found/register',
        component: TokenFailComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Forgot Password',
            titleTranslateKey: 'confirmationPage.title404RegisterTitle',
            contentTranslateKey: 'confirmationPage.title404RegisterContent',
            withAuth: false
        }
    },
    {
        path: 'not-found/reset',
        component: TokenFailComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Forgot Password',
            titleTranslateKey: 'confirmationPage.title404RegisterTitle',
            contentTranslateKey: 'confirmationPage.title404RegisterContent',
            withAuth: false
        }
    },
    {
        path: 'register/:token',
        component: ConfirmationComponent,
        canActivate: [AuthGuardService],
        data: {
            title: '',
            titleTranslateKey: 'forgotPassword.title',
            withAuth: false
        }
    },
    {
        path: 'forgot-password/reset/:token',
        component: ForgotPasswordResetComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Forgot Password',
            titleTranslateKey: 'forgotPassword.title',
            withAuth: false
        }
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(authRoutes),
        TranslateModule.forChild(),
        LocalizeRouterModule.forChild(authRoutes)
    ],
    exports: [
        RouterModule, LocalizeRouterModule
    ]
})
export class AuthRoutingModule {
}
