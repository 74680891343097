import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from '../field';

@Component({
  selector: 'app-dynamic-colorbox',
  templateUrl: './dynamic-colorbox.component.html',
})
export class DynamicColorboxComponent {
  @Input() field: FieldBase<any>;
  @Input() form: FormGroup;
  colorboxTextColor = '#fff';

  colorPickerChanged() {
      this.form.get(this.field.key).setValue(this.field.value);
      this.form.get(this.field.key).markAsDirty();
      if (this.isLightColor(this.field.value)) {
          this.colorboxTextColor = '#000';
      } else {
          this.colorboxTextColor = '#fff';
      }
  }

  isLightColor(color) {
      color = color.replace('#', '');
      const rgb = parseInt(color, 16);

      // tslint:disable-next-line:no-bitwise
      return (0.2126 * ((rgb >> 16) & 255) + 0.7152 * ((rgb >> 8) & 255) + 0.0722 * (rgb & 255)) >= 128;
  }
}
