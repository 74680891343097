import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextField } from '../field/field-text';

@Component({
  selector: 'app-dynamic-text',
  templateUrl: './dynamic-text.component.html',
})
export class DynamicTextComponent {
  @Input() field: TextField;
  @Input() form: FormGroup;
}
