import { Component, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { FieldBase } from './../../dynamic-form/field/field-base';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { CFG } from '@app/core';


@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss']
})
export class DialogFormComponent implements AfterViewInit {
  @ViewChild('df') private df: DynamicFormComponent;
  @Input() title: string;
  @Input() fields: FieldBase<any>[] = [];
  @Input() values: any;

  form: FormGroup;
  disableSave = true;
  startTime: moment.Moment = null;
  duration: moment.Moment = null;
  endTime: moment.Moment = null;
  private isUpdatingValue = false;

  constructor(
    public activeModal: NgbActiveModal,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit() {
    this.form = this.df.form;

    this.rangeFields();
    if (this.document.getElementById(this.fields[0].key)) {
      this.document.getElementById(this.fields[0].key).focus();
    }

    this.disableSave = false;
    if (this.values) {
      this.form.patchValue(this.values);
      this.changeTimeFields();
    }
    this.cdRef.detectChanges();
  }

  public saveForm() {
    if (!this.form.disabled && this.form.valid) {
      const values = this.form.value;
      // Close dialog and return new/edited item and parts
    this.activeModal.close({ values:  values});
    } else {
      this.form.enable();
    }
  }

  rangeFields() {
    let rangeFrom: AbstractControl = null,
        rangeTo: AbstractControl = null,
        rangeFromField: FieldBase<any> = null,
        rangeToField: FieldBase<any> = null;
    this.fields.forEach(field => {
      if (field['rangeMode'] === 'rangeFrom') {
        rangeFrom = this.form.get(field.key);
        rangeFromField = field;
      }
      if (field['rangeMode'] === 'rangeTo') {
        rangeTo = this.form.get(field.key);
        rangeToField  = field;
      }
    });
    if (rangeFrom && rangeTo) {
      rangeFrom.valueChanges
      .subscribe(v => {
        rangeToField['min'] = v;
      });

      rangeTo.valueChanges
      .subscribe(v => {
        rangeFromField['max'] = v;
      });
    }
  }

  changeTimeFields() {
    this.startTime = this.form.get('StartTime').value;
    this.duration = this.form.get('Duration').value;
    this.endTime = this.form.get('EndTime').value;

    if (this.startTime !== null && !isNaN(this.startTime.valueOf())) {
      if (this.duration !== null && !isNaN(this.duration.valueOf())) {
        this.calcEndTime();
      } else if (this.endTime !== null && !isNaN(this.endTime.valueOf())) {
        const diffInMinutes = this.endTime.clone().subtract(this.startTime.format(CFG.serverTimeFormats.shortTime), 'minutes');
        this.form.get('Duration').setValue(moment(diffInMinutes));
      }
    } else if (this.duration !== null && !isNaN(this.duration.valueOf())) {
      if (this.endTime !== null && !isNaN(this.endTime.valueOf())) {
        this.startTime = this.endTime.clone().subtract(this.duration.format(CFG.serverTimeFormats.shortTime), 'minutes');
        this.form.get('StartTime').setValue(this.startTime);
      }
    }

    this.form.get('StartTime').valueChanges.subscribe(event => {
      this.startTime = moment(event);

      if (isNaN(this.startTime.valueOf())) {
        this.form.get('EndTime').setValue(null, { emitEvent: true, onlySelf: false });
      } else {
        if (this.duration !== null && !isNaN(this.duration.valueOf())) {
          const duration = moment.duration(this.duration.format(CFG.serverTimeFormats.shortTime));
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const endTime = this.startTime.clone().add(moment.duration(hours, 'hours').add(minutes, 'minutes'));
          this.form.get('EndTime').setValue(endTime, { emitEvent: false, onlySelf: false });
        }
      }
    });

    this.form.get('Duration').valueChanges.subscribe(event => {
      if (!moment(event).isSame(this.duration)) {
        this.duration = moment(event);
        if (event && !isNaN(this.duration.valueOf())) {
          this.calcEndTime();
        } else {
          this.form.get('EndTime').setValue(null, { emitEvent: true, onlySelf: false });
        }
      }
    });

    this.form.get('EndTime').valueChanges.subscribe(event => {
      if (!moment(event).isSame(this.endTime)) {
        this.endTime = moment(event);

        if (isNaN(this.endTime.valueOf())) {
          return;
        } else {
          if (this.startTime !== null && !isNaN(this.startTime.valueOf())) {
            const diffInMinutes = this.endTime.clone().subtract(this.startTime.format(CFG.serverTimeFormats.shortTime), 'minutes');
            this.form.get('Duration').setValue(moment(diffInMinutes));
          } else if (this.duration !== null && !isNaN(this.duration.valueOf())) {
            this.startTime = this.endTime.clone().subtract(this.duration.format(CFG.serverTimeFormats.shortTime), 'minutes');
            this.form.get('StartTime').setValue(this.startTime);
          }
        }
      }
    });
  }

  calcEndTime() {
    const duration = moment.duration(this.duration.format(CFG.serverTimeFormats.shortTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const endTime = this.startTime.clone().add(moment.duration(hours, 'hours').add(minutes, 'minutes'));
    this.form.get('EndTime').setValue(endTime, { emitEvent: true, onlySelf: false });
  }
}
