import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { SliderField } from '../field';

@Component({
  selector: 'app-dynamic-slider',
  templateUrl: './dynamic-slider.component.html',
  styleUrls: ['./dynamic-slider.component.scss']
})
export class DynamicSliderComponent {
  @Input() field: SliderField;
  @Input() form: FormGroup;
}
