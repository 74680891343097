import { DOCUMENT } from '@angular/common';
import { SettingsService } from '@app/core/service';
import {
  Component,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  TemplateRef
} from '@angular/core';
import {
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ComponentType, CyComponent } from '../../model';
import * as uuid from 'uuid';
import {
  DynamicFormComponent,
  FieldBase,
  TextboxField,
  SelectboxField,
  TextareaField,
  Select2boxField,
  DateTimeField
} from '../../dynamic-form/index';

import { FieldValidationService } from '@app/core/dynamic-form/field-validation.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SharedDatatableComponent } from './../../../shared/datatable/shared-datatable.component';

@Component({
  selector: 'app-dialog-component',
  templateUrl: './dialog-component.component.html',
  styleUrls: ['./dialog-component.component.scss']
})
export class DialogComponentComponent implements OnInit, AfterViewInit {
  @ViewChild('partDt') partDt: SharedDatatableComponent;
  @ViewChild('belayPartDt') belayPartDt: SharedDatatableComponent;
  @ViewChild('deletePartTpl')
  deletePartTpl: TemplateRef<any>;
  @ViewChild('deleteBelayPartTpl')
  deleteBelayPartTpl: TemplateRef<any>;
  @ViewChild('dateTpl')
  dateTpl: TemplateRef<any>;
  @ViewChild('t')
  t: NgbTabset;
  @ViewChild('df')
  df: DynamicFormComponent;
  @ViewChild('dfPart')
  dfPart: DynamicFormComponent;
  @ViewChild('dfBelaySystemPart')
  dfBelaySystemPart: DynamicFormComponent;
  @ViewChild('partSelect')
  partSelect;
  @ViewChild('partDateAdded')
  partDateAdded;
  @Input()
  cyComponent: CyComponent;
  @Input()
  strHelper: any;
  @Input()
  partOptions: any;
  @Input()
  compTypes: ComponentType[];
  @Input()
  periodValues: any[];
  /* data for initializing */
  hideTablist = false;
  showRadioChoice = true;
  titleKey = 'dialogElement.title';

  /* variables for form */
  private form: FormGroup;
  public formPart: FormGroup;
  public formBelaySystemPart: FormGroup;
  public fields: FieldBase<any>[] = [];
  public fieldsPart: FieldBase<any>[] = [];
  public fieldsBelayPart: FieldBase<any>[] = [];
  private partField: Select2boxField;
  private belayPartField: Select2boxField;
  private lengthField: TextboxField;
  private deprecation: TextboxField;
  private deprecationPeriod: Select2boxField;
  private dateAdded: DateTimeField;
  public editMode = false;
  public typeOfComponent = '0';
  private periods;

  /* variables for parts */
  public showPartsContainer = false;
  public disableSavePart = true;
  public disableSaveBelayPart = true;

  // variable for parts tabs
  showBelaySystem = false;
  showParts = false;

  // variables of tables
  elementColumns: any[];
  belayColumns: any[];
  showPartDt = false;
  showBelayPartDt = false;

  // public parts: CyPart[] = [];
  public selectedPart: string;

  public date;
  private mainTranslateKey: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    public fieldValidationSvc: FieldValidationService,
    private translate: TranslateService,
    private settingsSvc: SettingsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.mainTranslateKey = 'dialogElement';
  }

  ngOnInit() {
    this.periods = this.settingsSvc.getPeriods();
    this.buildFields();
    this.buildColumns();

    this.cyComponent.BelaySystemParts = _.each(this.cyComponent.BelaySystemParts, (item) => item['uid'] = uuid.v4());
    this.cyComponent.Parts = _.each(this.cyComponent.Parts, (item) => item['uid'] = uuid.v4());
    if (this.strHelper.selectedItemType === 1 || this.strHelper.selectedItemType === 3 || this.strHelper.selectedItemType === 4) {
      this.showRadioChoice = false;
      this.showParts = true;
      this.showBelaySystem = true;
    }
  }

  ngAfterViewInit() {
    if (this.df) {
      this.form = this.df.form;
      this.document.getElementById(this.fields[0].key).focus();
    }

    if (this.dfPart) {
      this.formPart = this.dfPart.form;
      this.partField.options = <any>_.filter(this.partOptions, {
        ComponentTypeId: this.compTypes[+this.typeOfComponent].Id
      });
      this.disableSavePart = false;
    }

    if (this.dfBelaySystemPart) {
      this.formBelaySystemPart = this.dfBelaySystemPart.form;
      this.belayPartField.options = <any>(
        _.filter(this.partOptions, { ComponentTypeId: this.compTypes[2].Id })
      );
      this.disableSaveBelayPart = false;
    }

    this.form.get('Deprecation').valueChanges.subscribe((val) => {
      const valueOfDeprecationPeriod = this.form.get('DeprecationPeriod').value;
      if (val && !valueOfDeprecationPeriod) {
        this.form.get('DeprecationPeriod').setValue(this.periods[0].Id);
      }
    });

    this.form.valueChanges.subscribe(val => {
      if (
        (val.Deprecation || val.DeprecationPeriod) &&
        (!this.deprecation.required || !this.deprecationPeriod.required)
      ) {
        this.dateAdded.required = true;
        this.form.get('DateAdded').setValidators([Validators.required,  Validators.min(1)]);
        this.form.get('DateAdded').updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.form.get('DateAdded').markAsPristine();
        this.deprecation.required = true;
        this.form.get('Deprecation').setValidators(Validators.required);
        this.form
          .get('Deprecation')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.deprecationPeriod.required = true;
        this.form.get('DeprecationPeriod').setValidators(Validators.required);
        this.form
          .get('DeprecationPeriod')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }

      if (
        !!this.compTypes[+this.typeOfComponent].DeprecationRequired === false &&
        !val.Deprecation &&
        !val.DeprecationPeriod
      ) {
        this.dateAdded.required = false;
        this.form.get('DateAdded').clearValidators();
        this.form
          .get('DateAdded')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.deprecation.required = false;
        this.form.get('Deprecation').clearValidators();
        this.form.get('Deprecation').setValidators(Validators.min(1));
        this.form
          .get('Deprecation')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.deprecationPeriod.required = false;
        this.form.get('DeprecationPeriod').clearValidators();
        this.form
          .get('DeprecationPeriod')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.form.get('DateAdded').markAsDirty();
        this.form.get('Deprecation').markAsDirty();
        this.form.get('DateAdded').markAsDirty();
        this.df.onValueChanged();
      }
    });

    if (this.cyComponent.Id) {
      this.titleKey = 'dialogElement.titleEdit';
      this.form.patchValue(this.cyComponent);
      this.form.updateValueAndValidity();

      const keyOfType = this.compTypes.find(
        i => i.Id === +this.cyComponent.ComponentTypeId
      ).Key;

      this.typeOfComponent =
        keyOfType === 'Platform' || keyOfType === 'HelperCable' ? '1' : '0';
      this.editMode = true;
    }

    this.changeCompType();
    this.cdRef.detectChanges();
  }

  changeCompType() {
    if (!this.cyComponent.Id) {
      this.form.reset();
      this.initRequiredFields();
    }

    // Set general tab when component type changed
    if (this.t.activeId !== 'tab-general') {
      this.t.select('tab-general');
    } else {
      setTimeout(() => {
        this.document.getElementById(this.fields[0].key).focus();
      }, 100);
    }

    if (!!this.compTypes[+this.typeOfComponent].IncludeLength) {
      this.lengthField.hidden = false;
      this.form.get('Length').setValidators([Validators.min(0)]);
      this.form
        .get('Length')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
    } else {
      this.lengthField.hidden = !this.compTypes[+this.typeOfComponent]
        .IncludeLength;
      this.form.get('Length').clearValidators();
      this.form.get('Length').setValidators(Validators.min(0));
      this.form
        .get('Length')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }

    // Change options value by selected component type
    this.partField.options = <any>_.filter(this.partOptions, {
      ComponentTypeId: this.compTypes[+this.typeOfComponent].Id
    });

    // Tabs variables for displaying tab headers
    this.showBelaySystem = !!this.compTypes[+this.typeOfComponent]
      .IncludeBelaySystemParts;
    this.showParts = !!this.compTypes[+this.typeOfComponent].IncludeParts;
    this.hideTablist = +this.typeOfComponent ? true : false;
  }

  tabChanged(event) {
    if (event.nextId === 'tab-general') {
      setTimeout(() => {
        this.document.getElementById(this.fields[0].key).focus();
      }, 100);
    }

    if (event.nextId === 'tab-parts') {
      this.showPartDt = false;
      setTimeout(() => {
        // this.partDt.fixBugColumnResize();
        this.showPartDt = true;
      }, 100);
    }

    if (event.nextId === 'tab-belay-system') {
      this.showBelayPartDt = false;
      setTimeout(() => {
        // this.belayPartDt.fixBugColumnResize();
        this.showBelayPartDt = true;
      }, 100);
    }
  }

  private buildFields(): void {
    this.fields = [
      new TextboxField({
        key: 'Name',
        label: this.translate.instant(`${this.mainTranslateKey}.labelName`),
        required: true,
        autofocus: true,
        maxLength: 25,
        validators: [this.isUniqueNameValidator(this.strHelper.uniqueNames[this.strHelper.selectedRouteId])],
        errorMessages: {
          uniqueName: 'The name already exists.'
        }
      }),

      new TextareaField({
        key: 'Description',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelDescription`
        ),
      }),
      (this.lengthField = new TextboxField({
        type: 'number',
        key: 'Length',
        min: 0.1,
        label: this.translate.instant(`${this.mainTranslateKey}.labelLength`),
        errorMessages: {
          min: `The Length must be greater than 0`
        },
        maxLength: 50
      })),
      (this.deprecation = new TextboxField({
        key: 'Deprecation',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelDeprecation`,
        ),
        errorMessages: {
          min: `The Length must be greater than 0`
        },
        min: 1,
        required: true,
        type: 'number',
        styleClass: 'col-md-7'
      })),
      (this.deprecationPeriod = new Select2boxField({
        key: `DeprecationPeriod`,
        label: ' ',
        keyField: 'Id',
        valField: 'Name',
        placeholder: 'Deprecation Period',
        options: this.periods.filter(period => period.Key !== 'usage'),
        required: true,
        styleClass: 'col-md-5'
      })),
      this.dateAdded = new DateTimeField({
        key: 'DateAdded',
        label: this.translate.instant(`${this.mainTranslateKey}.labelDateAdded`),
        type: 'date',
        pickerType: 'calendar',
        max: moment().format('YYYY-MM-DD'),
      }),
    ];
    this.fieldsPart = [
      (this.partField = new Select2boxField({
        key: 'ComponentPartCategoryId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelPart`),
        keyField: 'Id',
        valField: 'Name',
        required: true
      })),
      new DateTimeField({
        key: 'DateAdded',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelDateAdded`
        ),
        type: 'date',
        pickerType: 'calendar',
        required: true,
        validators: [this.fieldValidationSvc.isNotFuture()],
        max: moment().format('YYYY-MM-DD'),
        errorMessages: {
          notFuture: 'Date Added cannot be in the future'
        }
      }),
    ];

    this.fieldsBelayPart = [
      (this.belayPartField = new Select2boxField({
        key: 'ComponentPartCategoryId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelPart`),
        keyField: 'Id',
        valField: 'Name',
        required: true
      })),
      new DateTimeField({
        key: 'DateAdded',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelDateAdded`
        ),
        type: 'date',
        pickerType: 'calendar',
        required: true,
        validators: [this.fieldValidationSvc.isNotFuture()],
        max: moment().format('YYYY-MM-DD'),
        errorMessages: {
          notFuture: 'Date Added cannot be in the future'
        }
      })
    ];
  }

  // Add new part to array of parts
  public addPart(partType: String) {
    if (partType === 'belaySystem' && !this.formBelaySystemPart.valid) {
      this.dfBelaySystemPart.updateErrors(true);
      return;
    }

    if (partType === 'Part' && !this.formPart.valid) {
      this.dfPart.updateErrors(true);
      return;
    }

    const values = partType === 'belaySystem' ? this.formBelaySystemPart.value : this.formPart.value;
    values.Name = this.getNameOfPartById(values.ComponentPartCategoryId);
    values.DateAdded = values.DateAdded ? moment(values.DateAdded).format('YYYY-MM-DD') : '';
    values.uid = uuid.v4();

    // Calculate deprecation
    const partCategory = _.find(this.partOptions, { Id: +values.ComponentPartCategoryId });
    const partDeprecation = partCategory.Deprecation;
    const partDeprecationPeriodId = partCategory.DeprecationPeriod;
    const partDeprecationPeriodKey = _.find(this.settingsSvc.getPeriods(), { Id: partDeprecationPeriodId }).Key;
    values.DeprecationDate = moment(values.DateAdded).add(
      partDeprecation,
      partDeprecationPeriodKey
    ).format('YYYY-MM-DD');

    if (partType === 'belaySystem') {
      this.cyComponent.BelaySystemParts.push(values);
      this.formBelaySystemPart.reset();
      this.cyComponent.BelaySystemParts = [...this.cyComponent.BelaySystemParts];
    } else {
      this.cyComponent.Parts.push(values);
      this.formPart.reset();
      this.cyComponent.Parts = [...this.cyComponent.Parts];
    }
  }

  // Delete existing part from array of parts by index
  public deletePartByIndex(uid: number, partType: String) {
    let index;
    if (partType === 'belaySystem') {
      index = this.cyComponent.BelaySystemParts.findIndex(item => item['uid'] === uid);
      this.cyComponent.BelaySystemParts.splice(index, 1);
      this.cyComponent.BelaySystemParts = [...this.cyComponent.BelaySystemParts];
    } else {
      index = this.cyComponent.Parts.findIndex(item => item['uid'] === uid);
      this.cyComponent.Parts.splice(index, 1);
      this.cyComponent.Parts = [...this.cyComponent.Parts];
    }
  }

  // Close element dialog
  public closeDialog() {
    this.activeModal.close();
  }

  // On submit form
  public saveForm() {
    if (!this.form.valid) {
      this.t.select('tab-general');
      this.markFormGroupDirty(this.df.form);
      this.df.onValueChanged();
      return;
    }
    const values = this.form.value;

    if (!this.cyComponent.Id) {
      this.cyComponent.Id = uuid.v4();
    }

    this.cyComponent.Name = values.Name;
    this.cyComponent.ComponentTypeId = this.compTypes[+this.typeOfComponent].Id;
    this.cyComponent.Description = values.Description || '';
    this.cyComponent.Length = values.Length;
    this.cyComponent.DeprecationPeriod = values.DeprecationPeriod;
    this.cyComponent.Deprecation = values.Deprecation;
    this.cyComponent.DateAdded = values.DateAdded ? moment(values.DateAdded).format('YYYY-MM-DD') : '';
    this.cyComponent.RouteId = this.strHelper.selectedRouteId;
    if (!this.compTypes[+this.typeOfComponent].IncludeParts) {
      this.cyComponent.Parts = [];
    }

    if (!this.compTypes[+this.typeOfComponent].IncludeBelaySystemParts) {
      this.cyComponent.BelaySystemParts = [];
    }

    this.activeModal.close({
      cyComponent: this.cyComponent,
      typeOfComponent: this.compTypes[+this.typeOfComponent].Key
    });
  }

  // mark as dirty all FormControls in a FormGroup
  private markFormGroupDirty(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsDirty();
    });
  }

  private getNameOfPartById(id: number): string {
    const tmpRouteElementPart = this.partOptions.find(p => p.Id === Number(id));
    return `${tmpRouteElementPart.Name} (${tmpRouteElementPart.Supplier})`;
  }

  private initRequiredFields() {
    this.deprecation.hidden = !this.compTypes[+this.typeOfComponent]
      .IncludeDeprecation;
    this.deprecationPeriod.hidden = !this.compTypes[+this.typeOfComponent]
      .IncludeDeprecation;
    this.deprecation.required = !!this.compTypes[+this.typeOfComponent]
      .DeprecationRequired;
    this.deprecationPeriod.required = !!this.compTypes[+this.typeOfComponent]
      .DeprecationRequired;
    this.dateAdded.required = !!this.compTypes[+this.typeOfComponent]
      .DeprecationRequired;

    // Add require validator by dynamically
    if (!!this.compTypes[+this.typeOfComponent].DeprecationRequired) {
      this.form.get('Deprecation').setValidators([ Validators.required, Validators.min(1)]);
      this.form
        .get('Deprecation')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.form.get('DeprecationPeriod').setValidators(Validators.required);
      this.form.get('DeprecationPeriod').setValue(this.periods[0].Id);
      this.form
        .get('DeprecationPeriod')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.form.get('DateAdded').setValidators(Validators.required);
      this.form
        .get('DateAdded')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
    } else {
      this.form.get('Deprecation').clearValidators();
      this.form
        .get('Deprecation')
        .updateValueAndValidity({ emitEvent: true, onlySelf: true });
      this.form.get('DeprecationPeriod').clearValidators();
      this.form
        .get('DeprecationPeriod')
        .updateValueAndValidity({ emitEvent: true, onlySelf: true });
      this.form.get('DateAdded').clearValidators();
      this.form
        .get('DateAdded')
        .updateValueAndValidity({ emitEvent: true, onlySelf: true });
    }
  }

  // custom validator for unique names
  isUniqueNameValidator(names: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const index = names.indexOf(control.value);
      const tmpEditMode = this.cyComponent.Id ? true : false;
      if (tmpEditMode && control.value === this.cyComponent.Name) {
        return null;
      } else {
        return index !== -1 ? { uniqueName: { value: control.value } } : null;
      }
    };
  }

  private buildColumns() {
    this.elementColumns = [
      {
        name: this.translate.instant('dialogElement.labelName'),
        prop: 'Name',
        draggable: false,
        flexGrow: 4
      },
      {
        name: this.translate.instant('dialogElement.labelDateAdded'),
        prop: 'DateAdded',
        draggable: false,
        cellTemplate: this.dateTpl,
        cellClass: 'mobile',
        flexGrow: 1
      },
      {
        name: this.translate.instant('dialogElement.labelDeprecation'),
        prop: 'DeprecationDate',
        draggable: false,
        cellTemplate: this.dateTpl,
        cellClass: 'mobile',
        flexGrow: 1
      },
      {
        draggable: false,
        cellTemplate: this.deletePartTpl,
        notResponsiveTitle: true,
        cellClass: 'mobile',
        flexGrow: 1
      }
    ];
    this.belayColumns = [
      {
        name: this.translate.instant('dialogElement.labelName'),
        prop: 'Name',
        draggable: false,
        flexGrow: 4
      },
      {
        name: this.translate.instant('dialogElement.labelDateAdded'),
        prop: 'DateAdded',
        draggable: false,
        cellTemplate: this.dateTpl,
        cellClass: 'mobile',
        flexGrow: 1
      },
      {
        name: this.translate.instant('dialogElement.labelDeprecation'),
        prop: 'DeprecationDate',
        draggable: false,
        cellTemplate: this.dateTpl,
        cellClass: 'mobile',
        flexGrow: 1
      },
      {
        draggable: false,
        cellTemplate: this.deleteBelayPartTpl,
        notResponsiveTitle: true,
        cellClass: 'mobile',
        flexGrow: 1
      }
    ];
  }
}
