import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService, AuthService, CFG, DynamicFormComponent, FieldBase, TextboxField, SettingsService, ApiService } from '@app/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ComponentTitle } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends ComponentTitle implements OnInit {
  @ViewChild('df') private df: DynamicFormComponent;

  wasSended = false;
  param = { emailAddress: '' };

  fields: FieldBase<any>[] = [];

  private forgotForm: FormGroup;
  mainTranslateKey: string;

  constructor(private router: Router,
    private apiSvc: ApiService,
    protected appSvc: AppService,
    public settingsSvc: SettingsService,
    private translate: TranslateService,
    private localize: LocalizeRouterService) {
    super(appSvc);
  }

  ngOnInit() {
    super.ngOnInit();

    this.mainTranslateKey = 'forgotPassword';

    this.buildFields();
  }

  buildFields(): void {
    this.fields = [
      new TextboxField({
        type: 'email',
        key: 'Email',
        label: this.translate.instant(`${this.mainTranslateKey}.email`),
        maxLength: 100,
        required: true,
      })
    ];
  }

  onSubmit(): void {
    // Get the form group from the dynamic form component
    const forgotForm = this.df.form;
    // Disable the form
    forgotForm.disable();

    // Call login request
    this.apiSvc
      .post('session/forgot', forgotForm.value)
      .subscribe(
        (data) => {
          this.param.emailAddress = forgotForm.value.Email;
          this.wasSended = true;
        },
        () => {

        });
  }


}
