import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@app/error/error.component';

import { CustomPreloadingStrategyService } from '@app/core';

import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
    return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

/**
 * NOTE: The `CanLoad` guard is cannot be used on the lazy-loaded modules, because
 * this will disable preloading of the lazy-loaded modules child lazy-loaded modules,
 * so we need to use the `CanActivate` guard on the lazy-loaded modules child routes.
 * Consequently, the app cannot able to restrict the loading of a restricted module,
 * when the user directly try to reach the restricted routes by the browser address bar,
 * but it can prevent the accessing of these routes/modules.
 */
const appRoutes: Routes = [
    { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
    { path: 'app', loadChildren: 'app/main/main.module#MainModule'},
    { path: '**', component: ErrorComponent, data: { title: '404 - Page Not Found' } }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            // enableTracing: false,
            preloadingStrategy: CustomPreloadingStrategyService
        }),
        LocalizeRouterModule.forRoot(appRoutes, {
            parser: {
              provide: LocalizeParser,
              useFactory: HttpLoaderFactory,
              deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            }
          })
    ],
    exports: [RouterModule, LocalizeRouterModule],
})
export class AppRoutingModule {
}
