import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
export class TranslateUniversalLoader implements TranslateLoader {

    constructor(private http: HttpClient) {

    }
    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): Observable<any> {
        lang = lang === 'en' ? 'en-GB' : lang;
        return this.http.get(`./assets/i18n/${lang}.json?version=${environment.version}`);
    }
  }
