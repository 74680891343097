import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class BeltStatusService {
    private statuses: BeltStatus[];

    constructor(private apiSvc: ApiService) { }

    getItems(): Observable<BeltStatus[]> {
        return this.statuses
            && of(this.statuses)
            || this.apiSvc
                .get('belt/status')
                .pipe(map((data: ApiResponseDataCollection) => this.statuses = data.Data));
    }
}
