import { SettingsService } from '@app/core';
import { LocalizedDatePipe } from '@app/core/pipes/localized-date.pipe';
import { TranslateService } from '@ngx-translate/core';

export class LocalizedComponent {
    localizedDatePipe: LocalizedDatePipe;
    constructor(
        protected settingsSvc: SettingsService,
        protected translate: TranslateService,
    ) {
        this.localizedDatePipe = new LocalizedDatePipe(translate, settingsSvc);
    }

    public getLocalizedDate(date: string, format = 'YYYY-MM-DD') {
        return this.localizedDatePipe.transform(date, format);
    }
}
