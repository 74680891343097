export class ComplaintReport {
    public Id: number;
    public ParkId: number;
    public DealtByUserId: number;
    public MethodOfSubmissionId: number;
    public ComplaintCategoryId: number;
    public ReporterTypeId: number;
    public Description: string;
    public Nazorg: string;
    public CauseAndCircumstances: string;
    public Solution: string;
    public GuestFirstName: string;
    public GuestLastName: string;
    public GuestPhone: string;
    public Date: string;
    public DateDealt: string;
    public DealtByUser: string;
    public ModifiedByUser: string;
    public Park: string;

    constructor(report: {
        Id?: number,
        ParkId?: number,
        DealtByUserId?: number,
        MethodOfSubmissionId?: number,
        ComplaintCategoryId?: number,
        ReporterTypeId?: number,
        Description?: string,
        Nazorg?: string,
        CauseAndCircumstances?: string,
        Solution?: string,
        GuestFirstName?: string,
        GuestLastName?: string,
        GuestPhone?: string,
        Date?: string,
        DateDealt?: string,
        DealtByUser?: string,
        ModifiedByUser?: string,
        Park?: string
    }) {
        this.Id                     = report.Id || 0;
        this.ParkId                 = report.ParkId || 0;
        this.DealtByUserId          = report.DealtByUserId || 0;
        this.MethodOfSubmissionId   = report.MethodOfSubmissionId || 0;
        this.ComplaintCategoryId    = report.ComplaintCategoryId || 0;
        this.ReporterTypeId         = report.ReporterTypeId || 0;
        this.Description            = report.Description || '';
        this.Nazorg                 = report.Nazorg || '';
        this.CauseAndCircumstances  = report.CauseAndCircumstances || '';
        this.Solution               = report.Solution || '';
        this.GuestFirstName         = report.GuestFirstName || '';
        this.GuestLastName          = report.GuestLastName || '';
        this.GuestPhone             = report.GuestPhone || '';
        this.Date                   = report.Date || '';
        this.DateDealt              = report.DateDealt || '';
        this.DealtByUser            = report.DealtByUser || '';
        this.ModifiedByUser         = report.ModifiedByUser || '';
        this.Park                   = report.Park || '';
    }
}
