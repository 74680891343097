import { FieldBase } from './field-base';

export class EditorField extends FieldBase<string> {
    controlType = 'editor';
    placeholder: string;

    constructor(options: {} = {}) {
        super(options);
        this.placeholder = options['placeholder'] || '';
    }
}
