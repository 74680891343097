export class Route {
    public Id: number;
    public ParkId: number;
    public Difficulty: number;
    public Name: string;
    public Color: string;
    public Active: Number;
    public Park: string;
    public Settings: { [key: string]: any};
    public DaysToInspections: any;
    public FileUrl: string;
    public Manuals: File[];
    public UncompletedServices: any[];
    ParkData: { [key: string]: any};
    constructor(
        params: {
            Id?: number,
            Parkid?: number,
            Difficulty?: number,
            Name?: string,
            Color?: string,
            Active?: Number,
            Park?: string,
            Settings?: { [key: string]: any},
            DaysToInspections?: any,
            FileUrl?: string,
            Manuals?: File[],
            UncompletedServices?: any[]
        }
    ) {
        this.Id = params['Id'] || 0;
        this.ParkId = params['ParkId'] || 0;
        this.Difficulty = params['Difficulty'] || 0;
        this.Name = params['Name'] || '';
        this.Color = params['Color'] || '#ffffff';
        this.Active = params['Active'] ? 1 : 0;
        this.Park = params['Park'] || '';
        this.Settings = params['Settings'] || {};
        this.DaysToInspections = params['DaysToInspections'] || {};
        this.FileUrl = params['FileUrl'] || '';
        this.Manuals = params['Manuals'] || [];
        this.UncompletedServices = params['UncompletedServices'] || [];
    }

    changeValuesToServer() {
        this.Active = this.Active ? 1 : 0;
    }
}
