import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CFG } from '../config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router,
      private translate: TranslateService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl.split('?')[0];
        this.currentUrl = event.url.split('?')[0];
        this.removeLanguageFromUrl();

        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public removeLanguageFromUrl() {
    const tmpCurrentLanguage = this.translate.currentLang;
    if (this.previousUrl.includes(`/${tmpCurrentLanguage}`)) {
      this.previousUrl = this.previousUrl.replace(`/${tmpCurrentLanguage}`, '');
    }

    if (this.currentUrl.includes(`/${tmpCurrentLanguage}`)) {
      this.currentUrl = this.currentUrl.replace(`/${tmpCurrentLanguage}`, '');
    }
  }

  public justLoaded() {
      return this.previousUrl === this.currentUrl;
  }
}
