export class RouteInspectionType {
    public Id: number;
    public Name: string;
    public SettingsType: number;
    public Default: number;
    public Key: string;
    constructor(
        params: {
            Id?: number,
            Name?: string,
            SettingsType?: number,
            Default?: number,
            Key?: string
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.SettingsType = params['SettingsType'] ? 1 : 0;
        this.Default = params['Default'] || 0;
        this.Key = params['Key'] || '';
    }
}
