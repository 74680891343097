import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { Role } from '../model';

export const RoleService = new InjectionToken<BaseFeatureModuleService<Role>>('Role');

export function roleSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<Role> {
    return new BaseFeatureModuleService<Role>(apiSvc, 'role');
}
