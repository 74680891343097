import { Component, Input } from '@angular/core';
import { TextareaField } from '../field';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-textarea',
  templateUrl: './dynamic-textarea.component.html',
  styleUrls: ['./dynamic-textarea.component.scss']
})
export class DynamicTextareaComponent {
  @Input() field: TextareaField;
  @Input() form: FormGroup;
}
