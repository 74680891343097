import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';


import { DailyReport } from '../../model';
import { BaseFeatureModuleService } from '../base-feature-module-service';
import { ApiService } from '../api.service';
@Injectable()
export class BaseRouteInspectionService extends BaseFeatureModuleService<any> {

    private reports: any[];

    constructor(
        private apiSvcC: ApiService,
        private pathC: string,
        private reqParamsC: { getItems?: StringStringMap, getItem?: StringStringMap } = {}
    ) {
        super(apiSvcC, pathC, reqParamsC);
    }

    getItemsWithMeta(metaInfo: any): Observable<{ list: any[], _meta: any }> {
        this.reqParamsC.getItems = Object.assign(this.reqParamsC.getItems, metaInfo);

        return this.apiSvcC
            .get(this.pathC, this.reqParamsC.getItems || {})
            .pipe(map((data: ApiResponseDataCollection) => {
                data._meta.pageNumber = data._meta.currentPage - 1;
                return { list: data.Data, _meta: data._meta };
            }));
    }

    reopenRouteService(routeServiceId: number) {
        const path = `route/service/${routeServiceId}/reopen`;
        return this.apiSvcC.post(path);
    }
}
