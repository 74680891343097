import { Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { WINDOW } from '../window-factory';

import { SideNavService } from './sidenav.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(-100%, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            transition('in => out', animate('300ms cubic-bezier(0, 0, 0.3, 1)')),
            transition('out => in', animate('200ms cubic-bezier(0, 0, 0.3, 1)'))
        ]),
    ]
})
export class SideNavComponent implements OnInit, OnDestroy {
    @HostBinding('@slideInOut')
    get slideInOut() {
        return this.menuState;
    }

    private menuState: string;
    private stateSubscr: Subscription;
    private timer: any;

    private clickEvent = 'click';
    private closeListenerAttached = false;
    private _onClickOutside = this.onClickOutside.bind(this);

    constructor(@Inject(WINDOW) private window: Window,
                @Inject(DOCUMENT) private document: Document,
                public el: ElementRef,
                private sideNavService: SideNavService) { }

    ngOnInit(): void {
        // Check is an iOS device and touch events exists.
        if (
            /iPad|iPhone|iPod/.test(this.window.navigator.userAgent) && !(this.window as any).MSStream
            && 'ontouchstart' in this.window
        ) {
            this.clickEvent = 'touchstart';
        }

        // Subscribe for the state changes.
        this.stateSubscr = this.sideNavService
            .state$
            .subscribe((open: boolean) => {
                this.menuState = open && 'out' || 'in';

                if (open) {
                    this.initCloseListener();
                } else {
                    this.destroyCloseListener();
                }
            });
    }

    // open(): void {
    //     this.sideNavService.open();
    // }

    close(): void {
        this.sideNavService.close();
    }

    // toggle(): void {
    //     this.sideNavService.toggle();
    // }

    ngOnDestroy(): void {
        this.stateSubscr.unsubscribe();
        this.destroyCloseListener();
        clearTimeout(this.timer);
    }

    /**
     * Handles `click` events on anything while the sidebar is open.
     * Programatically closes the sidebar if a click occurs outside the sidebar.
     *
     * @param {MouseEvent} e Mouse click event.
     */
    private onClickOutside(e: MouseEvent): void {
        if (this.el && !this.el.nativeElement.contains(e.target)) {
            this.close();
        }
    }

    /**
     * Initialize event handler for the clicking on the document.
     */
    private initCloseListener(): void {
        if (!this.closeListenerAttached && this.menuState === 'out') {
            // Set click outside handler in a timeout, so the animation can be started.
            this.timer = setTimeout(() => {
                this.document.addEventListener(this.clickEvent, this._onClickOutside);
                this.closeListenerAttached = true;
            }, 0);
        }
    }

    /**
     * Destroys the event handler from the `initCloseListeners()`.
     */
    private destroyCloseListener(): void {
        if (this.closeListenerAttached) {
            this.document.removeEventListener(this.clickEvent, this._onClickOutside);
            this.closeListenerAttached = false;
        }
    }
}
