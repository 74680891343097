import { CyElement, CyPart } from '@app/core/model';

export class  CyEdge {
    public group = 'edges';
    public data: {
                    compId: string,
                    source: string,
                    target: string,
                    lineStyle: string,
                    routeId: number,
                    type: string,
                    color: string,
                };
    constructor() {
        this.data = {
            compId: '',
            source: '',
            target: '',
            lineStyle: '',
            routeId: 0,
            type: '',
            color: ''
        };
    }

    public setRouteId(routeId: number) {
        this.data.routeId = routeId;
    }

    public setDirection(source: string, target: string) {
        this.data.source = source;
        this.data.target = target;
    }

    public setData(data: any) {
        this.data = data;
    }

    public getId(): string {
        return this.data['id'];
    }
}
