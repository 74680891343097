import { Route } from '../route.model';

export class Inspection {
    public Id: number;
    public DateAdded: string;
    public InspectionType: string;
    public ImportanceLevel: number;
    public User: string;
    public Route: Route;
    constructor(
        params?: {
            Id?: number,
            DateAdded?: string,
            InspectionType?: string,
            ImportanceLevel?: number,
            User?: string,
            Route?: Route
        }
    ) {
        this.Id = params['Id'] || 0;
        this.DateAdded = params['DateAdded'] || '';
        this.InspectionType = params['InspectionType'] || '';
        this.ImportanceLevel = params['ImportanceLevel'] || 0;
        this.User = params['User'] || '';
        this.Route = params['Route'] || new Route({});
    }
}
