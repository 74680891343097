import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { UtilService } from './util.service';

import { DailyReport, Park } from '../model';
import { BaseFeatureModuleService } from './base-feature-module-service';
@Injectable()
export class BasesParkService extends BaseFeatureModuleService<Park> {

    private parks: Park[];

    constructor(
        private apiSvcC: ApiService,
        private pathC: string,
        private reqParamsC: { getItems?: StringStringMap, getItem?: StringStringMap } = {}
    ) {
        super(apiSvcC, pathC, reqParamsC);
    }

    getItemsLocally(): Observable<Park[]> {
        return this.parks
            && of(this.parks)
            || this.apiSvcC
                .get(this.pathC, { expand: 'Routes' })
                .pipe(map((data: any) => this.parks = data.Data));
    }

    getItemLocally(id): Observable<Park> {
        const park = this.parks.find( (item) => item.Id === Number(id) );
        return of(park);
    }

}
