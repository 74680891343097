import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';

import {Observable, forkJoin, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {
  BaseFeatureModuleService,
  BeltCategory,
  BeltCategoryService,
  CFG,
  Park,
  ParkService, SettingsService,
  ToastService, User, UserService
} from '@app/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Injectable()
export class BeltServiceListDependencyResolverService implements Resolve<Observable<BeltServiceDependencies[]>> {
  constructor(private router: Router,
              private toastSvc: ToastService,
              private localize: LocalizeRouterService,
              private settingsSvc: SettingsService,
              @Inject(ParkService) private parkSvc: BaseFeatureModuleService<Park>,
              @Inject(BeltCategoryService) private beltCategorySvc: BaseFeatureModuleService<BeltCategory>,
              @Inject(UserService) private userService: BaseFeatureModuleService<User>
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BeltServiceDependencies[]> {
    const equipmentType = route.data.equipmentType;
    return forkJoin(
      this.parkSvc.getItemsWithParams({params: {fields: 'Id,Name,Active'}}),
      this.beltCategorySvc.getItemsWithParams({params: {'equipment-type': `${equipmentType}`, fields: 'Id,Name'}}),
      this.userService.getItemsWithParams({params: {fields: 'Id,FirstName,LastName,FileUrl,Image'}}),
      this.settingsSvc.getBeltServiceCheckTypes(),
    ).pipe(
      map((data: [Park[], BeltCategory[], User[], any[]]) =>
        ({parks: data[0], beltCategories: data[1], users: data[2], beltServiceCheckTypes: data[3]})),
      catchError((err: HttpErrorResponse) => {
        if (err && err.status === 404) {
          this.toastSvc.error(CFG.error.E008);
        }

        if (err && err.status !== 401) {
          this.router.navigate([this.localize.translateRoute('/app/dashboard')]);
        }

        return of(null);
      })
    );
  }
}


export interface BeltServiceDependencies {
  parks: Park[];
  beltCategories: BeltCategory[];
  users: User[];
  beltServiceCheckTypes: any[];
}
