import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateTimeField } from '../field';

@Component({
  selector: 'app-dynamic-date-time',
  templateUrl: './dynamic-date-time.component.html',
})
export class DynamicDateTimeComponent {
  @Input() field: DateTimeField;
  @Input() form: FormGroup;

  dateKeyDown($event, type) {
    if (type === 'timer' && $event.code !== 'Backspace') {
      $event.preventDefault();
    }
  }
}
