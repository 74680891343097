import { CyPart } from '@app/core/model';
export class CyElement {
    public id: string;
    public name: string;
    public description: string;
    public length: string;
    public dateAdded: string;
    public type: number;
    public routeId: number;

    public updateDataFromForm(value: any) {
        this.id = value.id;
        this.name = value.name;
        this.description = value.description;
        this.length = value.length || 0;
        this.type = value.type;
        this.dateAdded = value.dateAdded || '';
    }

    public getDataForForm() {
        return {
            type: this.type,
            name: this.name,
            description: this.description,
            length: this.length,
            dateAdded: this.dateAdded
        };
    }

    public getName(): string {
        return this.name;
    }

    public getId(): string {
        return this.id;
    }

    public setRouteId(routeId: number) {
        this.routeId = routeId;
    }
}
