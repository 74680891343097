import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Role } from '@app/core';

@Injectable()
export class CheckTypeService {
    private checktypes: CheckType[];

    constructor(private apiSvc: ApiService) { }

    getItems(): Observable<Role[]> {
        return this.checktypes
            && of(this.checktypes)
            || this.apiSvc
                .get('belt/service/check-type')
                .pipe(map((data: ApiResponseDataCollection) => this.checktypes = data.Data));
    }
}
