import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { ServiceBelt } from '../model';

export const ServiceBeltService = new InjectionToken<BaseFeatureModuleService<ServiceBelt>>('ServiceBelt');

export function serviceBeltSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<ServiceBelt> {
    return new BaseFeatureModuleService<ServiceBelt>(apiSvc, 'belt/service',
    {
        getItems: {
            expand: 'Category,Reporter,CheckType,Belt'
        },
        getItem: {
            expand: 'Belt,Category,CheckType,Reporter,Photos'
        }
    }
);
}
