import { Component, Input } from '@angular/core';
import { RadioboxField } from '../field';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-radiobox',
  templateUrl: './dynamic-radiobox.component.html',
  styleUrls: ['./dynamic-radiobox.component.scss']
})
export class DynamicRadioboxComponent {
  @Input() field: RadioboxField;
  @Input() form: FormGroup;
}
