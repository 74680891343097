export class CyPart {
    public Id: string;
    public RouteElementPartCategoryId: number;
    public DateAdded: string;
    public DeprecationDate: string;
    public Name: string;
    constructor(
        params: {
            id?: number,
            RouteElementPartCategoryId?: number,
            DateAdded?: string,
            DeprecationDate?: string
            Name?: string
        }
    ) {
        this.Id = params['Id'] || 0;
        this.RouteElementPartCategoryId = params['RouteElementPartCategoryId'] || 0;
        this.DateAdded = params['DateAdded'] || '';
        this.DeprecationDate = params['DeprecationDate'] || '';
        this.Name = params['Name'] || '';
    }
}
