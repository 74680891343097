import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { User } from '../model';

export const UserService = new InjectionToken<BaseFeatureModuleService<User>>('UserService');

export function userSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<User> {
    return new BaseFeatureModuleService<User>(apiSvc, 'user',
        { getItems: { expand: 'RoleIds,Roles,ParkIds,Confirmed', all: '' }, getItem: { expand: 'ParkIds,RoleIds,Confirmed' } });
}
