export class ParkDesignUpdate {
    public Id: number;
    public Name: string;
    public Image: string;
    public Active: Number;
    public Data: string;
    public RouteComponents: any[];

    constructor(params) {
        this.Id = params['Id'] || '';
        this.Name = params['Name'] || '';
        this.Image = params['Image'] || '';
        this.Active = params['Active'] ? 1 : 0;
        this.Data = params['Data'] || '';
        this.RouteComponents = params['RouteComponents'] || [];
    }
}
