import { FieldBase } from './field-base';

export class DropzoneField extends FieldBase<string> {
    controlType = 'dropzone';
    config: any;
    path: string;
    base64Mode: boolean;
    message: string;
    customPreview: boolean;

    constructor(options: {} = {}) {
        super(options);
        this.config = options['config'] || [];
        this.label = options['label'] || '';
        this.path = options['path'] || '';
        this.base64Mode = options['base64Mode'] || false;
        this.message = options['message'] || 'Click or drag images here to upload';
        this.customPreview = options['customPreview'] || false;
    }
}
