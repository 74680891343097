import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from '../service/util.service';
import { TokenService } from '../auth/token.service';
import { WINDOW } from '../window-factory';


@Injectable()
export class SettingsProvider {

    private settings: any = null;
    private languages = ['en-GB', 'en', 'nl', 'de'];
    private afterLanguage = ['app', 'sign-in', 'forgot-password'];
    private invalidLang = false;

    constructor(private http: HttpClient,
        private utilSvc: UtilService,
        private tokenSvc: TokenService,
        @Inject(WINDOW) private window: Window
    ) {

    }

    public getSettings(): any {
        return this.settings;
    }

    load() {
        return new Promise((resolve, reject) => {
            let lang: any = this.window.location.pathname.split('/')[1];
            this.invalidLang = this.languages.indexOf(lang) < 0 ? true : false;
            lang = this.invalidLang ? 'en-GB' : lang;

            this.http
                .get(this.utilSvc.buildUrl('settings', lang),
                    // tslint:disable-next-line:max-line-length
                    this.utilSvc.buildHttpRequestOptions({ expand: 'Languages,Company,Modules,CoPermissions,UserPermissions,BeltStatuses,Periods,Roles,EquipmentTypes,InspectionTypeApplicationScopes,RouteProblemsCnt,BeltServiceCheckTypes' }))
                .subscribe((response: ApiResponse) => {
                    this.settings = response.data;

                    if (this.invalidLang) {
                        let url: any = this.window.location.pathname.split('/');
                        if (this.afterLanguage.indexOf(url[1]) < 0) {
                            url[1] = this.settings.Language;
                        } else {
                            url[0] = this.settings.Language;
                        }
                        url = url.join('/');
                        this.window.location.href = url;
                        reject(false);
                    } else {
                        resolve(true);
                    }
                },
                    (err) => {
                        resolve(true);
                    });
        });
    }
}
