import {ChangeDetectorRef} from '@angular/core';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Data} from '@angular/router';
import {AppService} from '@app/core';

export class BaseComponent {
  deps: any;
  title = '';
  titleTranslateKey = '';
  contentTranslateKey = '';
  routeDataSubscr: Subscription;

  constructor(
    protected appSvc: AppService,
    protected chdRef: ChangeDetectorRef,
    protected route: ActivatedRoute) {
  }

  getDependencies(): void {
    this.appSvc
      .routeData$
      .pipe(
        take(1)
      )
      .subscribe((routeData: Data) => {
        this.title = routeData.title && routeData.title.toUpperCase() || '';
        this.titleTranslateKey = routeData.titleTranslateKey || '';
        this.contentTranslateKey = routeData.contentTranslateKey || '';
      });

    this.routeDataSubscr = this.route.data
      .pipe(take(1))
      .subscribe((data: { deps: any }) => {
        this.deps = data.deps;
        this.chdRef.detectChanges();
      });
  }

  getDependencyByKey(key: string, defaultValue: any) {
    return this.deps && this.deps[key] || defaultValue;
  }
}
