import { InjectionToken } from '@angular/core';

import { BaseDailyReportService } from './base-daily-report-service';
import { ApiService } from './api.service';

import { ComplaintReport, IncidentReport } from '../model';
import { BaseFeatureModuleService } from '@app/core/service/base-feature-module-service';

export const IncidentReportService = new InjectionToken<BaseFeatureModuleService<ComplaintReport>>('IncidentReport');

export function incidentReportSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<IncidentReport> {
    return new BaseFeatureModuleService<IncidentReport>(apiSvc, 'report/incident', {
        getItems: { expand: 'Park,ModifiedByUser,RIAndETested,Status' },
        getItem: { expand: 'Status' }
    });
}
