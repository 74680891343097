import * as moment from 'moment';
import 'moment-timezone';
import { CFG } from '../config';

export class RescueReport {
    public Id: number;
    public ParkId: number;
    public DealtByUserId: number;
    public RouteId: number;
    public RouteComponentId: string;
    public BeltId: number;
    public AgeIds: any[];
    public Reason: string;
    public NoOfPersons: number;
    public DateTime: any;
    public Height: number;

    constructor(report: {
        Id?: number;
        ParkId?: number;
        DealtByUserId?: number;
        RouteId?: number;
        RouteComponentId?: string;
        BeltId?: number;
        AgeIds?: any[];
        Reason?: string;
        NoOfPersons?: number;
        DateTime?: any;
        Height?: number;
        }
    ) {
        this.Id = report.Id || 0;
        this.ParkId = +report.ParkId || 0;
        this.DealtByUserId = report.DealtByUserId || 0;
        this.RouteComponentId = report.RouteComponentId || '';
        this.RouteId = report.RouteId || 0;
        this.BeltId = report.BeltId || 0;
        this.AgeIds = report.AgeIds || [];
        this.Reason = report.Reason || '';
        this.NoOfPersons = report.NoOfPersons || 0;
        this.DateTime = report.DateTime || '';
        this.Height = report.Height || 0;
    }

    public prepareForClient(timeZone: string) {
      this.DateTime = new Date(moment.utc(this.DateTime).tz(timeZone).format(CFG.serverTimeFormats.dateTime));
  }

  public prepareForServer(timeZone: string) {
      const dateTimeString = moment(this.DateTime).format(CFG.serverTimeFormats.dateTime);
      this.DateTime = moment.tz(dateTimeString, timeZone).utc().format(CFG.serverTimeFormats.dateTime);
  }
}
