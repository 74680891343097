import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';

import { ApiService } from './api.service';

import { BeltPartCategory } from '../model';
import { BasesParkService } from '@app/core/service/base-park-service';

export const BeltPartCategoryService = new InjectionToken<BaseFeatureModuleService<BeltPartCategory>>('BeltPartCategoryService');

export function beltPartCategorySvcFactory(apiSvc: ApiService): BaseFeatureModuleService<BeltPartCategory> {
    return new BaseFeatureModuleService<BeltPartCategory>(apiSvc, 'belt/part-category', {
        getItems: { expand: 'Supplier,PartType' },
        getItem: { expand: 'Manuals,InspectionTypeIds'}
    });
}
