export class BeltPartType {
    public Id: number;
    public Name: string;
    public Default: number;
    public EquipmentTypeId: number;
    public EquipmentType: string;

    constructor(
        params: {
            Id?: number,
            Name?: string,
            Default?: number,
            EquipmentTypeId?: number,
            EquipmentType?: string

        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.Default = params['Default'] || 0;
        this.EquipmentTypeId = params['EquipmentTypeId'] || 0;
        this.EquipmentType = params['EquipmentType'] || '';
    }
}
