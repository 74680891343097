import {BeltPart} from './belt-part.model';
import {EquipmentInterface} from './belt/equipment.interface';
import {MaintenanceComment} from '@app/core/model/belt/types';

export class Belt implements EquipmentInterface {
  public type = 'equipment-edit';
  public Id: number;
  public CategoryId: number;
  public Barcode: string;
  public ParkId: number;
  public Park: string;
  public StatusId: number;
  public Name: string;
  public Description: string;
  public DateBought: string;
  public DateAdded: string;
  public DateUpdated: string;
  public DeprecationDate: string;
  public ModifiedByUser: string;
  public UsagesCnt: number;
  public DaysUsed: number;
  public Category: string;
  public UsagesCntToMaintain: number;
  public DaysToMaintain: number;
  public Manuals: any[];
  public Status?: string;
  public Inspections?: any[];

  public Parts: BeltPart[];
  public Service: {
    Id: number,
    FileUrl?: any,
    Description?: string,
    RepairDescription?: string,
    Photos?: any[],
    RepairPhotos?: any[],
    DueDate?: string;
    AssignedTo: any,
    Comments: MaintenanceComment[]
    Parts?: any[]
  };

  constructor(params: {
    Id?: number,
    CategoryId?: number,
    Barcode?: string,
    ParkId?: number,
    Park?: string,
    StatusId?: number,
    Name?: string,
    Description?: string,
    DateBought?: string,
    DateAdded?: string,
    DateUpdated?: string,
    DeprecationDate?: string,
    ModifiedByUser?: string,
    UsagesCnt?: number,
    DaysUsed?: number,
    Category?: string,
    UsagesCntToMaintain?: number,
    DaysToMaintain?: number,
    Manuals?: any[],
    Parts?: BeltPart[],
    Status?: string,
    Inspections?: any[],
    Service?: {
      Id: number,
      FileUrl: any,
      Description: string,
      RepairDescription: string,
      Photos: any[],
      RepairPhotos: any[],
      AssignedTo: any,
      Comments: MaintenanceComment[]
    };
  }) {
    this.Id = params['Id'] || 0;
    this.CategoryId = params['CategoryId'] || null;
    this.Barcode = params['Barcode'] || '';
    this.ParkId = params['ParkId'] || null;
    this.Park = params['Park'] || '';
    this.StatusId = params['StatusId'] || 0;
    this.Name = params['Name'] || '';
    this.Description = params['Description'] || '';
    this.DateBought = params['DateBought'] || null;
    this.DateAdded = params['DateAdded'] || '';
    this.DateUpdated = params['DateUpdated'] || '';
    this.DeprecationDate = params['DeprecationDate'] || '';
    this.ModifiedByUser = params['ModifiedByUser'] || '';
    this.UsagesCnt = params['UsagesCnt'] || 0;
    this.DaysUsed = params['DaysUsed'] || 0;
    this.Category = params['Category'] || '';
    this.UsagesCntToMaintain = params['UsagesCntToMaintain'] || 0;
    this.DaysToMaintain = params['DaysToMaintain'] || 0;
    this.Manuals = params['Manuals'] || [];
    this.Parts = params['Parts'] || [];
    this.Service = params['Service'] || null;
    this.Status = params['Status'] || null;
    this.Inspections = params['Inspections'] || [];
  }
}
