export class CyNode {
  public group = 'nodes';
  public data: {
    Name: string,
    elements: any[],
    type: number;
    width?: number,
    height?: number,
    rotate?: number,
    points?: number[],
    polygonWidth?: number;
    polygonHeight?: number;
    routeId?: number;
  };

  public position: { x: number, y: number };

  constructor(id: string, name: string, type: number, routeId: number = 0) {
    this.data = {Name: name, elements: [], type: type};
    if (type === 4) {
      this.data.height = 100;
      this.data.width = 200;
      this.data.polygonWidth = 200;
      this.data.polygonHeight = 100;
      this.data.rotate = 0;
      this.data.routeId = routeId;
      this.data.points = [
        -1, -1,
        1, -1,
        1, 1,
        -1, 1
      ];
    }
  }

  updateData(data: any) {
    this.data.Name = data.name;
  }

  setPosition(x: number, y: number) {
    this.position = {x: x, y: y};
  }
}
