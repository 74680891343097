import { InjectionToken } from '@angular/core';

import { BaseDailyReportService } from './base-daily-report-service';
import { ApiService } from './api.service';

import { DailyReport } from '../model';

export const DailyReportService = new InjectionToken<BaseDailyReportService>('DailyReportService');

export function dailyReportSvcFactory(apiSvc: ApiService): BaseDailyReportService {
    return new BaseDailyReportService(
        apiSvc, 'report/daily',
        {
            getItems: { expand: 'Park,ModifiedByUser' },
            getItem: { expand: '' }
        }
    );
}
