export class BeltPartCategory {
    Id: number;
    PartTypeId: number;
    SupplierId: number;
    Name: string;
    Price: Number;
    Description: string;
    Deprecation: number;
    DeprecationPeriod: number;
    Supplier: string;
    FileUrl: string;
    Manuals: File[];
    InspectionTypeIds: number[];
    constructor(
        params: {
            Id?: number,
            PartTypeId?: number,
            SupplierId?: number,
            Name?: string,
            Price?: Number,
            Description?: string,
            Deprecation?: number,
            DeprecationPeriod?: number,
            Supplier?: string,
            FileUrl?: string,
            Manuals?: File[],
            InspectionTypeIds?: number[]
        }
    ) {
        this.Id = params['Id'] || 0;
        this.PartTypeId = params['PartTypeId'] || 0;
        this.SupplierId = params['SupplierId'] || 0;
        this.Name = params['Name'] || '';
        this.Price = params['Price'] || 0;
        this.Description = params['Description'] || '';
        this.Deprecation = params['Deprecation'] || 0;
        this.DeprecationPeriod = params['DeprecationPeriod'] || 0;
        this.Supplier = params['Supplier'] || '';
        this.FileUrl = params['FileUrl'] || '';
        this.Manuals = params['Manuals'] || [];
        this.InspectionTypeIds = params['InspectionTypeIds'] || [];
    }
}
