import { SelectboxField } from './field-selectbox';

export class SelectboxMultiField extends SelectboxField {
    controlType = 'selectboxmulti';
    multiple: boolean;

    constructor(options: {} = {}) {
        super(options);
        this.multiple = options['multiple'] || false;
    }
}
