import { FieldBase } from './field-base';

export class ArrayboxField extends FieldBase<string> {
    controlType = 'arraybox';
    fields: FieldBase<any>[];

    constructor(options: {} = {}) {
        super(options);
        this.fields = options['fields'] || [];
        // this.placeholder = options['placeholder'] || this.label && ('Enter ' + this.label.toLowerCase()) || '';
    }
}
