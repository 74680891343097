import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CFG } from '../config/index';


@Injectable()
export class ToastService {

    constructor(private toastSvc: ToastrService) {
    }

    success(msg: string): void {
        this.toastSvc.success(msg, '', {
            progressBar: false,
            timeOut: 1500,
          });
    }

    error(msg: string): void {
        this.toastSvc.error(msg, '', {
            timeOut: 0,
            extendedTimeOut: CFG.toastDuration,
          });
    }

    warning(msg: string): void {
        this.toastSvc.warning(msg, '', {
            progressBar: false,
            timeOut: 1500,
        });
    }

    errorFromArray(msgs: any[]): void {
        msgs.forEach(msg => this.error(msg.message));
    }

    info(msg: string): void {
        this.toastSvc.info(msg, '', {
            progressBar: true,
            timeOut: 1500
        });
    }
}
