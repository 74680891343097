import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ComponentType } from '../model';

@Injectable()
export class ComponentTypeService {

    constructor(private apiSvc: ApiService) { }

    getItems(args: { params?: StringStringMap } = {}): Observable<ComponentType[]> {
        return  this.apiSvc
                .get('route/component-type', args.params)
                .pipe(map((data: ApiResponseDataCollection) => data.Data));
    }
}
