import { Component, OnInit, Input } from '@angular/core';
import { RouteType } from '@app/core/model/route-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-routetype',
  templateUrl: './dialog-routetype.component.html',
})
export class DialogRoutetypeComponent implements OnInit {
  @Input() title: string;
  @Input() routeTypes: RouteType[];

  selectedRouteTypeId: number;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.selectedRouteTypeId = this.routeTypes.find(e => !!e).Id;
  }

  routeTypeChanged(id) {
    this.selectedRouteTypeId = id;
  }

  closeDialog () {
    if (this.selectedRouteTypeId) {
      this.activeModal.close(this.selectedRouteTypeId);
    }
  }

}
