import { FieldBase } from './field-base';

export class SelectboxField extends FieldBase<string> {
    controlType = 'selectbox';
    options: SelectOptionsObj[];
    keyField: string;
    valField: string;
    multiple: boolean;
    hideWhileNoOptions: boolean;
    default: { value: any, label: string };

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
        this.multiple = options['multiple'] || false;
        this.keyField = options['keyField'] || 'key';
        this.valField = options['valField'] || 'value';
        this.hideWhileNoOptions = options['hideWhileNoOptions'] || false;
        this.default = options['default'] || null;
    }
}
