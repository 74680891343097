import { Component, Input } from '@angular/core';
import { FieldBase } from '../field';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-recaptcha',
  templateUrl: './dynamic-recaptcha.component.html',
})
export class DynamicRecaptchaComponent {
  @Input() field: FieldBase<any>;
  @Input() form: FormGroup;

  constructor(public translate: TranslateService) {}
}
