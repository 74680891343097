import { Data } from '@angular/router';
import { Component, Input, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  DynamicFormComponent,
  FieldBase,
  SelectboxMultiField,
  SelectboxField,
  Select2boxField,
} from '../../dynamic-form/index';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-dialog-route-inspection',
  templateUrl: './dialog-route-inspection.component.html'
})
export class DialogRouteInspectionComponent implements OnInit, AfterViewInit {
  @ViewChild('df') private df: DynamicFormComponent;
  @Input() model: { RouteId: number, InspectionTypeId: number };
  @Input() routes: any[];
  @Input() inspectionTypes: any[];

  /* variables for form */
  public form: FormGroup;
  public fields: FieldBase<any>[] = [];
  private routeField: SelectboxField;
  private inspectionTypeField: SelectboxField;
  // Until we dont have form save is disable
  saveDisable = true;
  mainTranslateKey: string;
  constructor(public activeModal: NgbActiveModal,
    public cdRef: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit() {
    this.mainTranslateKey = 'dialogRouteInspection';

    // Build form
    this.buildFields();
  }

  ngAfterViewInit() {
    // Get the form from dynamic form and set saveDisable to false,
    // because form  instance exists and we can check the validation of it
    this.form = this.df.form;
    this.saveDisable = false;

    // Set the form options
    this.routeField.options = <any>this.routes;
    this.inspectionTypeField.options = <any>this.inspectionTypes;

    // Patch form data
    this.form.patchValue(this.model);
    this.cdRef.detectChanges();
  }

  private buildFields(): void {
    this.fields = [
      this.routeField = new Select2boxField({
        key: 'RouteId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelRoute`),
        required: true,
        keyField: 'Id',
        valField: 'Name'
      }),

      this.inspectionTypeField = new Select2boxField({
        key: 'InspectionTypeId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelInspectionType`),
        required: true,
        keyField: 'Id',
        valField: 'Name'
      }),
    ];
  }

  saveForm() {
    const values = this.form.value;
    const routeName = this.routes.find(i => i.Id === Number(values.RouteId)).Name;
    const routeInpsectionTypeName = this.inspectionTypes.find(i => i.Id === Number(values.InspectionTypeId)).Name;

    // Close dialog and return selected attributes
    this.activeModal.close({
      routeId: values.RouteId,
      routeName: routeName,
      routeInspectionId: values.InspectionTypeId,
      routeInspectionTypeName: routeInpsectionTypeName
    });
  }

}
