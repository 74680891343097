import { Inspection } from './inpection.model';

export class RouteServiceHistory {
    public Id: number;
    public DateAdded: string;
    public DateCompleted: string;
    public ImportanceLevel: number;
    public Inspection: Inspection;
    constructor(
        params: {
            Id?: number,
            DateAdded?: string,
            DateCompleted?: string,
            ImportanceLevel?: number,
            Inspection?: Inspection
        }
    ) {
        this.Id = params['Id'] || 0;
        this.DateAdded = params['DateAdded'] || '';
        this.DateCompleted = params['DateCompleted'] || '';
        this.ImportanceLevel = params['ImportanceLevel'] || 0;
        this.Inspection = params['Inspection'] || new Inspection();
    }
}
